.basket_page {
  padding-top: 16px;
  padding-bottom: 60px;

  .basket_page_main {
    .basket_page_main_head {
      display: flex;
      align-items: flex-end;
      column-gap: 12px;
      margin-bottom: 16px;

      .basket_page_headline {
        color: #141316;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      span {
        display: block;
        color: #75758b;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.3px;
      }
    }

    .basket_page_main_wrap {
      display: flex;
      column-gap: 24px;

      // order select styles
      .select_products {
        flex: 3;
        border-radius: 20px;
        border: 1px solid #e6e5ed;
        background: #fff;
        padding: 20px;

        .select_products_head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 16px;
          border-bottom: 1px solid #e5e7e9;
          margin-bottom: 16px;

          input {
            display: none;
          }

          input:checked + label::before {
            background: #000000;
            background-image: url(../assets/svg/checked.svg);
            background-size: 80%;
            background-position: 50%;
            background-repeat: no-repeat;
            border: 1px solid #000000;
          }

          label {
            position: relative;
            transition: all ease 0.5s;
            cursor: pointer;
            color: #334150;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.3px;
            padding-left: 30px;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: -2px;
              width: 20px;
              height: 20px;
              border-radius: 5px;
              border: 1px solid #000000;
              cursor: pointer;
            }

            &:hover {
              transition: all ease 0.5s;
              color: #000000;
            }
          }

          .delete_btn {
            color: #334150;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.3px;
            border: none;
            background: transparent;
            transition: all ease 0.3s;

            &:hover {
              cursor: pointer;
              transition: all ease 0.3s;
              color: #e30613;
            }
          }
        }

        .select_products_wrapper {
          display: flex;
          flex-direction: column;
          row-gap: 12px;

          .select_products_main {
            display: flex;
            padding-bottom: 12px;
            border-bottom: 1px solid #e5e7e9;

            &:last-child {
              border: none;
            }

            .select_products_main_left {
              display: flex;
              align-items: flex-start;
              column-gap: 10px;
              flex: 2;

              .select_input {
                display: none;
              }

              .select_input:checked + .select_label::before {
                background: #000000;
                background-image: url(../assets/svg/checked.svg);
                background-size: 80%;
                background-position: 50%;
                background-repeat: no-repeat;
                border: 1px solid #000000;
              }

              .select_label {
                position: relative;
                transition: all ease 0.5s;
                cursor: pointer;
                color: #334150;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                letter-spacing: 0.3px;
                padding-left: 30px;

                &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: -2px;
                  width: 20px;
                  height: 20px;
                  border-radius: 5px;
                  border: 1px solid #000000;
                  cursor: pointer;
                }

                &:hover {
                  transition: all ease 0.5s;
                  color: #000000;
                }
              }

              img {
                border-radius: 8px;
                object-fit: contain;
              }

              .select_products_main_left_texts {
                .select_products_main_left_texts_head {
                  display: flex;
                  align-items: center;
                  column-gap: 8px;
                  margin-bottom: 8px;

                  span {
                    display: block;
                    border-radius: 28px;
                    color: #fff;
                    text-align: center;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    border: 1px solid transparent;
                    height: 24px;
                    padding: 0 8px;
                  }
                }

                .headline {
                  display: block;
                  color: #334150;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  margin-bottom: 4px;
                }

                .article {
                  display: block;
                  color: #75758b;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 130%;
                  letter-spacing: 0.3px;
                  margin-bottom: 8px;
                }

                .other_info {
                  display: block;
                  color: #334150;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 130%;
                  letter-spacing: 0.3px;
                  margin-bottom: 4px;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }

            .select_products_main_right {
              flex: 0.5;

              .select_products_main_right_add {
                display: flex;
                align-items: center;
                column-gap: 8px;
                margin-bottom: 8px;

                button {
                  cursor: pointer;
                  width: 32px;
                  height: 32px;
                  border-radius: 8px;
                  border: 1px solid rgba(229, 231, 233, 0.5);
                  background: #fff;

                  &:last-child {
                    background: #000000;
                    border-color: #000000;
                  }
                }

                .qty {
                  display: block;
                  width: 50%;
                  height: 32px;
                  border-radius: 8px;
                  border: 1px solid #e5e7e9;
                  background: #fff;

                  color: #334150;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 32px;
                  text-align: center;
                }
              }

              .delete_btn {
                display: block;
                margin-left: auto;
                width: 32px;
                height: 32px;
                border-radius: 8px;
                background: rgba(227, 6, 19, 0.12);
                border: 1px solid rgba(227, 6, 19, 0.12);

                transition: all ease 0.5s;

                svg {
                  transition: all ease 0.5s;
                }

                &:hover {
                  cursor: pointer;
                  transition: all ease 0.5s;

                  svg {
                    transition: all ease 0.5s;
                    transform: scale(1.1);
                  }
                }
              }
            }
          }
        }
      }

      // order details styles
      .order_details {
        flex: 1;
        border-radius: 16px;
        border: 1px solid #e6e5ed;
        background: #fff;
        padding: 20px;

        .order_details_title {
          color: #334150;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 20px;
        }

        .order_details_text_box {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
          margin: 12px 0;

          .order_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .key {
            flex: 1;
            color: #75758b;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.3px;
          }

          .value {
            flex: 1;
            color: #334150;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: end;
          }
        }

        .order_details_main {
          padding-bottom: 20px;
          border-bottom: 1px solid #e5e7e9;
          margin-bottom: 20px;
          width: 360px;

          .order_details_text_wrap {
            margin-bottom: 15px;
            display: flex;

            .key {
              flex: 1;
              color: #75758b;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 130%;
              letter-spacing: 0.3px;
              text-align: start;
            }

            .value {
              text-align: end;
              flex: 1;
              color: #334150;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }

        .order_details_bottom {
          .order_details_total {
            display: flex;
            margin-bottom: 20px;

            .key {
              flex: 1;
              color: #75758b;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 130%;
              letter-spacing: 0.3px;
              text-align: start;
            }

            .value {
              flex: 2;
              color: #334150;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-align: end;
            }
          }

          .order_details_btn {
            display: block;
            width: 100%;
            padding: 12.5px;
            border-radius: 8px;
            background: #000000;
            border: 1px solid #000000;
            text-align: center;

            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            transition: all ease 0.5s;
            margin-bottom: 12px;

            &:hover {
              transition: all ease 0.5s;
              color: #000000;
              background: transparent;
              cursor: pointer;
            }
          }

          .order_details_delivery {
            position: relative;
            color: #334150;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
            letter-spacing: 0.2px;
            padding-left: 25px;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              background: url(../assets/svg/track.svg);
              background-repeat: no-repeat;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }

  // Basket no data styles
  .basket_page_no_data {
    display: block;
    margin-right: auto;
    margin-left: auto;

    .basket_page_no_data_img {
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px;
    }

    h2 {
      color: #141316;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
      text-align: center;
      margin-bottom: 12px;
    }

    span {
      display: block;
      color: #334150;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.3px;
      max-width: 400px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px;
    }

    a {
      position: relative;
      display: block;
      max-width: 360px;
      width: 100%;
      height: 48px;
      border-radius: 8px;
      background: #000000;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      text-align: center;
      margin: 0 auto;
      transition: all ease 0.5s;

      &:hover {
        transition: all ease 0.5s;

        &::after {
          transform: scale(1.2);
        }
      }

      &::after {
        content: "";
        position: absolute;
        background: url(../assets/svg/navigate.svg);
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        top: 13px;
        transition: all ease 0.5s;
      }
    }
  }
}
