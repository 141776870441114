.header__fixed {
  z-index: 100 !important;
}

.product {
  padding-top: 16px;

  .product_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 50px;
    .product_header_right {
      .product_modal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: -1;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-radius: 16px;
      }

      .product_info {
        width: 460px;
        background-color: #ffffff;
        border-radius: 16px;
        .product_info_header {
          display: flex;
          align-items: center;
          column-gap: 10px;

          .product_info_header_review_count {
            display: flex;
            align-items: center;
            column-gap: 5px;
          }

          .product_info_header_review {
            color: #334150;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
          }
        }

        .product_info_title {
          color: #141316;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 135%;
          letter-spacing: 0.2px;
          margin-bottom: 20px;
        }

        // select styles
        .product_info_select {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          margin-bottom: 20px;

          .product_info_select_title {
            display: block;
            color: #141316;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 135%;
            letter-spacing: 0.2px;
            margin-bottom: 8px;

            b {
              font-weight: 400;
            }
          }

          // color style
          .product_info_select_color {
            display: flex;
            align-items: center;
            column-gap: 10px;

            div {
              width: 32px;
              height: 32px;
              border-radius: 6px;
              border: 1px solid grey;
              cursor: pointer;
            }
          }

          // size style
          .product_info_select_size {
            display: flex;
            align-items: center;
            column-gap: 10px;

            div {
              width: 68px;
              height: 38px;
              border-radius: 8px;
              border: 1px solid #e6e5ed;
              background: #fff;
              cursor: pointer;
              color: #21201f;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              text-align: center;
              line-height: 38px;
            }
          }
        }

        // product info navigate style
        .product_info_navigate {
          display: flex;
          align-items: center;
          column-gap: 12px;
          padding: 12px 16px;
          border-radius: 12px;
          border: 1px solid #f4f6f6;
          background: #f4f6f6;
          margin-bottom: 20px;

          h4 {
            color: #0f1722;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 135%;
            letter-spacing: 0.07px;
          }

          span {
            display: block;
            position: relative;
            color: #21201f;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0.06px;

            &::after {
              content: "";
              position: absolute;
              background: url(../../assets/svg/productArrow.svg);
              background-repeat: no-repeat;
              width: 16px;
              height: 16px;
            }
          }
        }

        // product price styles
        .product_price {
          width: 100%;
          border-radius: 16px;
          border: 1px solid #e6e5ed;
          background: #fff;
          padding: 18px;

          .product_price_headline {
            display: block;
            color: #334150;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
            letter-spacing: 0.2px;
          }

          .product_price_sum {
            display: block;
            color: #334150;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 16px;
          }

          .product_price_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 12px;
            margin-bottom: 12px;
          }

          .product_crud {
            width: 135px !important;
          }
          .product_card_bottom_add_result {
            display: block;
            width: 165px;
            height: 42px;
            border-radius: 8px;
            border: 1px solid #e5e7e9;
            background: #fff;

            color: #334150;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 42px;
            text-align: center;
          }

          .product_price_add {
            display: flex;
            align-items: center;
            column-gap: 10px;
            justify-content: center;
            border-radius: 8px;
            background: #000000;
            border: 1px solid #000000;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: 42px;
            height: 42px;

            svg {
              transition: all ease 0.5s;
            }

            &:hover {
              cursor: pointer;
              transition: all ease 0.5s;

              svg {
                transition: all ease 0.5s;
                transform: scale(1.1);
              }
            }
          }

          .active__btn {
            background-color: #e6e8ed !important;
            color: #36374066 !important;
            border-color: transparent;
          }

          .product_price_back {
            display: flex;
            align-items: center;
            column-gap: 10px;
            justify-content: center;
            border-radius: 8px;
            background: transparent;
            border: 1px solid #e5e7e980;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            // width: 86%;
            width: 42px;
            height: 42px;

            svg {
              transition: all ease 0.5s;
            }

            &:hover {
              cursor: pointer;
              transition: all ease 0.5s;

              svg {
                transition: all ease 0.5s;
                transform: scale(1.1);
              }
            }
          }

          .product_price_wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 12px;
            margin-bottom: 12px;

            .product_card_bottom_add_result {
              display: block;
              width: 50%;
              height: 42px;
              border-radius: 8px;
              border: 1px solid #e5e7e9;
              background: #fff;

              color: #334150;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 42px;
              text-align: center;
            }

            .product_price_add {
              display: flex;
              align-items: center;
              column-gap: 10px;
              justify-content: center;
              border-radius: 8px;
              background: #000000;
              border: 1px solid #000000;

              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              width: 86%;
              height: 42px;

              svg {
                transition: all ease 0.5s;
              }

              &:hover {
                cursor: pointer;
                transition: all ease 0.5s;

                svg {
                  transition: all ease 0.5s;
                  transform: scale(1.1);
                }
              }
            }

            .active__btnsecond {
              background-color: #e6e8ed !important;
              color: #36374066 !important;
              border-color: transparent;

              svg {
                color: #36374066 !important;
              }
            }

            .product_price_like {
              position: relative;
              width: 42px;
              height: 42px;
              border-radius: 8px;
              background: #ebf9f6;
              border: 1px solid #ebf9f6;
              transition: all ease 0.5s;

              &:hover {
                cursor: pointer;
                transition: all ease 0.5s;
                border-color: #000000;
                background: transparent;
              }

              &::after {
                content: "";
                position: absolute;
                background: url(../../assets/svg/like.svg);
                background-repeat: no-repeat;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 20px;
                height: 20px;
              }
            }
          }

          .product_price_delivery {
            position: relative;
            display: block;
            color: #334150;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
            letter-spacing: 0.2px;
            padding-left: 25px;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              background: url(../../assets/svg/track.svg);
              background-repeat: no-repeat;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }

  .product__wrapper {
    display: flex;
    gap: 16px;
  }

  .product__left {
    width: 80px;
    padding: -1px;
  }

  .slick-next {
    display: none !important;
  }

  .slick-prev {
    display: none !important;
  }

  .product__box {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;
    background: #f4f6f6;
    margin-bottom: 12px;
    border-radius: 12px;
    border: 1px solid transparent;
  }

  .product__box.active {
    border: 1px solid #000000;
  }

  .product_topss {
    width: 600px !important;
    background-color: #f4f6f6;
    height: 480px;
    border-radius: 30px;
    padding: 40px 50px;
  }

  .product__top {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .product_main_info {
    .product_main_info_header {
      display: flex;
      align-items: center;
      column-gap: 12px;
      margin-bottom: 20px;

      span {
        display: block;
        height: 42px;
        padding: 10px 24px;
        border-radius: 8px;
        border: 1px solid #e5e7e9;
        background: #fff;
        transition: all ease 0.5s;

        &:hover {
          cursor: pointer;
          transition: all ease 0.5s;
          border-radius: 8px;
          background: #000000;
          border-color: #000000;
          color: #fff;
        }
      }

      .active {
        border-radius: 8px;
        background: #000000;
        color: #fff;
        border-color: #000000;
      }
    }

    .product_main_info_bottom {
      border-radius: 20px;
      border: 1px solid #e6e5ed;
      background: #fff;
      padding: 24px;
      margin-bottom: 60px;

      .product_main_info_bottom_title {
        color: #141316;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 135%;
        margin-bottom: 20px;
      }

      .description {
        transition: all ease 0.5s;

        .product_main_info_bottom_description {
          color: #334150;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          letter-spacing: 0.3px;
        }
      }

      .characteristics {
        transition: all ease 0.5s;

        .characteristics_table {
          width: 100%;
          tbody {
            tr {
              margin-bottom: 16px;

              &:last-child {
                margin: 0;
              }

              .key {
                position: relative;
                color: #656b70;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                width: 70%;

                &::before {
                  border-bottom: 1px dotted #656b70;
                  content: "";
                  display: block;
                  height: 20px;
                  left: 18%;
                  position: absolute;
                  top: -5px;
                  width: 80%;
                  z-index: 1;
                }
              }

              .value {
                color: #0f1722;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
                letter-spacing: 0.3px;
                width: 30%;
              }
            }
          }
        }
      }

      .in_active {
        display: none;
        transition: all ease 0.5s;
      }
    }
  }

  .reviews {
    .review_header {
      display: flex;
      align-items: center;
      column-gap: 12px;
      margin-bottom: 20px;

      h2 {
        color: #141316;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 135%;
      }

      span {
        display: block;
        color: #334150;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.3px;
      }
    }

    .review_main {
      display: flex;
      align-items: flex-start;
      column-gap: 24px;
      padding-bottom: 30px;

      .review_left {
        width: 65%;
        .review_left_card {
          border-radius: 20px;
          border: 1px solid #e6e5ed;
          background: #fff;
          padding: 24px;

          .review_left_card_top {
            display: flex;
            align-items: center;
            column-gap: 12px;
            margin-bottom: 12px;

            img {
              border-radius: 9px;
              object-fit: contain;
            }

            h3 {
              color: #141316;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 135%;
            }
          }

          .star {
            margin-bottom: 8px;
          }

          .review_left_card_description {
            color: #334150;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            letter-spacing: 0.3px;
            margin-bottom: 12px;
          }

          .review_left_card_img_wrap {
            display: flex;
            align-items: center;
            column-gap: 12px;
            margin-bottom: 20px;

            .img_block {
              display: block;
              width: 60px;
              height: 60px;
              border-radius: 12px;

              img {
                object-fit: contain;
              }
            }
          }

          .review_left_card_bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .review_left_card_bottom_left {
              display: flex;
              align-items: center;
              column-gap: 12px;

              button {
                display: flex;
                align-items: center;
                column-gap: 10px;

                border-radius: 9px;
                border: 1px solid #e6e5ed;
                background: #fff;
                height: 38px;

                color: #21201f;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 135%;
                padding-right: 20px;
                padding-left: 20px;
              }
            }

            .review_left_card_bottom_right {
              display: block;

              color: #334150;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              letter-spacing: 0.3px;
            }
          }
        }
      }

      .review_right {
        width: 30%;
        border-radius: 20px;
        border: 1px solid #e6e5ed;
        background: #fff;
        padding: 12px;

        .review_right_head {
          border-radius: 12px;
          background: rgba(244, 246, 246, 0.7);
          height: 180px;
          padding-top: 45px;
          margin-bottom: 24px;

          .star {
            margin-right: auto;
            margin-left: auto;
            width: fit-content;
          }

          .title {
            display: block;
            color: #141316;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 135%;
          }

          .rating {
            display: block;
            text-align: center;
            color: #334150;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
          }
        }
      }
    }
  }
}
