.favorites_page {
  padding-top: 16px;
  padding-bottom: 60px;

  .favorites_page_main {
    .favorites_page_main_title {
      color: #141316;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
      margin-bottom: 20px;
    }

    .favorites_page_main_wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 24px;
      row-gap: 30px;
    }
  }

  .favorites_page_no_data {
    display: block;
    margin-right: auto;
    margin-left: auto;

    .favorites_page_no_data_img {
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px;
    }

    h2 {
      color: #141316;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
      text-align: center;
      margin-bottom: 12px;
    }

    span {
      display: block;
      color: #334150;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.3px;
      max-width: 500px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px;
    }

    a {
      position: relative;
      display: block;
      max-width: 360px;
      width: 100%;
      height: 48px;
      border-radius: 8px;
      background: #000000;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      text-align: center;
      margin: 0 auto;
      transition: all ease 0.5s;

      &:hover {
        transition: all ease 0.5s;

        &::after {
          transform: scale(1.2);
        }
      }

      &::after {
        content: "";
        position: absolute;
        background: url(../assets/svg/navigate.svg);
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        top: 13px;
        transition: all ease 0.5s;
      }
    }
  }
}
