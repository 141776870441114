.profile {
  display: flex;
  column-gap: 24px;
  min-height: 50vh;
  position: relative;
  padding-bottom: 60px;

  .profile_said_bar {
    max-width: 265px;
    width: 100%;

    .profile_said_bar_header {
      margin-bottom: 20px;

      h5 {
        color: #141316;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 135%;
      }

      span {
        color: #75758b;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .profile_said_bar_link {
      a {
        transition: all ease 0.5s;
        padding: 13px 16px;
        border-radius: 12px;
        background: #f4f6f6;
        border: 1px solid #f4f6f6;

        display: flex;
        align-items: center;
        column-gap: 8px;

        color: #0f1722;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 135%;
        letter-spacing: 0.07px;

        margin-bottom: 8px;

        &:last-child {
          margin: 0;
        }

        svg {
          transition: all ease 0.5s;
          color: #000000;
        }

        &:hover {
          transition: all ease 0.5s;
          color: #fff;

          background-color: #000000;

          svg {
            transition: all ease 0.5s;
            color: #fff;
          }
        }
      }

      .active {
        background: #000000;
        color: #ffff;
        border-color: #000000;

        svg {
          color: #fff;
        }
      }

      .profile_logout {
        transition: all ease 0.5s;
        height: 56px;
        width: 100%;
        padding: 0 16px;
        display: flex;
        align-items: center;
        column-gap: 8px;

        border-radius: 12px;
        background: #fce1e3;
        border: 1px solid #fce1e3;

        color: #dd2033;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 135%;
        letter-spacing: 0.07px;

        &:hover {
          cursor: pointer;
          transition: all ease 0.5s;
          color: #fff;
          background: #dd2033;
        }
      }
    }
  }

  .orders,
  .details {
    padding-top: 73px;
    width: 100%;
    height: 100%;
  }

  // order page styles
  .order_main_btn_wrap {
    display: flex;
    align-items: flex-start;
    column-gap: 12px;
    margin-bottom: 16px;

    button {
      transition: all ease 0.5s;
      height: 48px;
      border-radius: 12px;
      background: #f4f6f6;
      border: 1px solid #f4f6f6;

      color: #0f1722;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 135%;
      letter-spacing: 0.07px;
      padding: 0 28px;
      cursor: pointer;

      &:hover {
        transition: all ease 0.5s;
        border-color: #000000;
        background: #000000;
        color: #fff;
      }
    }

    .active {
      border-color: #000000;
      background: #000000;
      color: #fff;
    }
  }

  .order_main {
    padding: 24px;
    border-radius: 20px;
    border: 1px solid #e6e5ed;
    background: #fff;

    .order_main_title {
      color: #334150;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
      margin-bottom: 20px;
    }

    .order_main_wrap {
      margin-bottom: 20px;

      .order_main_wrap_table {
        display: flex;
        margin-bottom: 15px;

        &:last-child {
          margin: 0;
        }

        .key {
          flex: 1;
          color: #75758b;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          letter-spacing: 0.3px;
        }

        .status {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          letter-spacing: 0.3px;

          padding: 3px 12px;
          border-radius: 8px;
          background: #1a5ee3;
        }

        .value {
          color: #334150;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .order_main_card {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &:last-child {
        margin: 0;
      }

      .order_main_card_left {
        flex: 1;
        display: flex;
        align-items: flex-start;
        column-gap: 12px;

        img {
          object-fit: contain;
          border-radius: 8px;
        }

        .order_main_card_left_info {
          .headline {
            display: block;
            color: #334150;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          span {
            display: block;
            color: #75758b;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            letter-spacing: 0.3px;
            margin-bottom: 8px;

            &:last-child {
              color: #334150;
              margin: 0;
            }
          }
        }
      }

      .order_main_card_price {
        color: #334150;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0.3px;
      }
    }

    .order_main_show_btn {
      cursor: pointer;
      height: 42px;
      width: 100%;

      border-radius: 9px;
      border: 1px solid #e6e5ed;
      background: #fff;

      color: #21201f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;

      strong {
        font-family: 500;
      }
    }
  }

  .order_main_no_data {
    padding: 60px;

    border-radius: 20px;
    border: 1px solid #e6e5ed;
    background: #fff;

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    h3 {
      color: #141316;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
    }

    span {
      display: block;
      color: #334150;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.3px;
    }
  }

  // none style
  .order_none {
    display: none;
  }

  // Details page styles
  .details_main {
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #e6e5ed;
    background: #fff;

    .details_main_title {
      color: #141316;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e5e7e9;
    }

    form {
      .details_main_form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        margin-bottom: 20px;

        label {
          color: #141316;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%;
          letter-spacing: 0.3px;
        }

        input,
        select {
          height: 50px;
          width: 100%;
          padding: 0 16px;
          margin-top: 8px;

          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 135%;

          ::placeholder {
            color: #334150;
          }

          border-radius: 12px;
          border: 1px solid #f0f1f2;
          background: #fff;
        }
      }

      button {
        transition: all ease 0.5s;
        max-width: 160px;
        width: 100%;
        height: 48px;
        border-radius: 8px;
        background: #000000;
        border: 1px solid #000000;

        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;

        &:hover {
          transition: all ease 0.5s;
          color: #000000;
          background: transparent;
        }
      }
    }
  }
}
